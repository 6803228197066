<template>
    <div v-if="!hidden" class="notificacoes" @click.stop>
        <div class="titulo">
            <div class="d-flex align-items-center">
                <button class="btn p-0 mr-1 my-close-btn" @click="$emit('close')" title="Fechar"><x-circle-icon /></button>
                <h4>Notificações</h4>
            </div>
            <div v-show="loading" class="loader"></div>
            <a href="#" @click.prevent="setAllLidas">Marcar todas como lidas</a>
        </div>
        <div class="conteudo-container" :class="{ vazio: notificacoes.length === 0 }">
            <virtual-list  class="conteudo" v-if="notificacoes.length > 0"
                :data-key="'id'"
                :data-sources="notificacoes"
                :data-component="NotificationItem"
                :estimate-size="81"
            />

            <div v-else class="nenhuma-notificacao">
                <sun-icon size="96" />
                <p class="mt-3 mb-0">Nenhuma notificação não lida.</p>
                <p class="mt-0">Bom trabalho!</p>
            </div>
        </div>
    </div>
</template>

<script>
import loginService from '@/services/login';
import api from '../../api';
import axios from 'axios';
import Sockette from 'sockette';
import VirtualList from 'vue-virtual-scroll-list'
import NotificationItem from './NotificationItem'

let funcaoLogin, funcaoNotificacao, funcaoToggle;

/** @type {WebSocket|null} */
let realTimeMeasurementWS = null;

function startMeasurement(idDetector) {
    stopMeasurement();
    const token = loginService.getBearerToken();
    if (!token) return;
    realTimeMeasurementWS = new WebSocket(api.socket.medicoes(token, idDetector));
    realTimeMeasurementWS.onmessage = (ev) => {
        try {
            let { v } = JSON.parse(ev.data)
            document.getElementById('updateable-measurement-notification').innerText = (''+v.toFixed(2)).replace('.', ',');
        } catch (err) {
            const d = document.getElementById('updateable-measurement-notification');
            if (d) d.innerText = 'ERRO';
        }
    };
    realTimeMeasurementWS.onclose = (ev) => {
        const d = document.getElementById('updateable-measurement-notification');
        if (d) d.innerText = 'ERRO: '+ev.code;
    };
}
function stopMeasurement() {
    if (realTimeMeasurementWS) realTimeMeasurementWS.close();
    realTimeMeasurementWS = null;
}

export default {
    components: {
        VirtualList,
    },
    data() {
        return {
            NotificationItem,
            notificacoes: [],
            hidden: true,
            count: 0,
            loading: false,
            ws: null,
            dataNotificacoes: 0,
            needLoad: false,
            needLoadNotify: false,
        };
    },
    methods: {
        toggle() {
            this.hidden = !this.hidden;
            if (!this.hidden) {
                this.load();
                document.addEventListener('click', funcaoToggle);
            } else {
                document.removeEventListener('click', funcaoToggle);
            }
        },
        notify(notificacoes) {
            notificacoes.reverse(); // antigas primeiro
            for (const notificacao of notificacoes) {
                if (!notificacao.idAlarme) return;
                let conf = {
                    timeout: 5000,
                    buttons: [{
                        text: 'Detalhes',
                        action: (toast) => {
                            this.showDetails(notificacao, true);
                            this.$snotify.remove(toast.id);
                        },
                        bold: true,
                    },
                    {
                        text: 'Fechar',
                        action: null,
                        bold: false,
                    }],
                };
                if (notificacao.severidade === 'Alerta') {
                    this.$snotify.warning(notificacao.mensagem, conf);
                } else if (notificacao.severidade === 'Alarme') {
                    this.$snotify.error(notificacao.mensagem, conf);
                }
            }
            notificacoes.reverse(); // volta à ordem original do array
        },
        async load(notify = false) {
            if (this.loading) {
                if (notify) this.needLoadNotify = true;
                else this.needLoad = true;
                return;
            }
            this.loading = true;
            let notificacoes;
            try {
                notificacoes = (
                    await axios.get(
                        api.v1.notificacao.list(this.dataNotificacoes)
                    )
                ).data;
            } catch (err) {
                // só tenta buscar notificações novamente se o erro não for de permissão
                if (![401, 403].includes(err?.response?.status)) {
                    this.$snotify.error(
                        'Impossível buscar novas notificações, tentando novamente em 5 segundos'
                    );
                    setTimeout(() => {
                        this.loading = false;
                        this.load(notify);
                    }, 5 * 1000);
                } else {
                    this.$snotify.error(
                        'Impossível buscar novas notificações, faça login novamente'
                    );
                    this.loading = false;
                }
                return;
            }

            // como as notificações estão ordenadas por ordem de chegada
            // descrescente, a primeira do vetor é sempre a mais recente
            if (notificacoes.length > 0) {
                this.dataNotificacoes =
                    new Date(notificacoes[0].dataHora).getTime() +
                    1;
                // coloca data com o formato local
                notificacoes.forEach(n => {
                    n.dataHora = new Date(
                        n.dataHora
                    ).toLocaleString();
                    n.lido = false;
                });
                this.notificacoes.unshift(...notificacoes);
                if (notify) this.notify(notificacoes);
            }
            this.loading = false;
            if (this.needLoad || this.needLoadNotify) {
                let needNotify = this.needLoadNotify;
                this.needLoad = this.needLoadNotify = false;
                this.load(needNotify);
            }
        },
        async update() {
            this.count = (await axios.get(api.v1.notificacao.naoLidas)).data;
            this.$emit('count', this.count);
        },
        async marcarLida(notification) {
            const {
                id,
                lido,
            } = notification;
            if (!lido) {
                this.count -= (
                    await axios.put(api.v1.notificacao.setLida(id))
                ).data;
                notification.lido = true;
                this.$emit('count', this.count);
            }
        },
        async showDetails(notification, noToggle = false) {
            const { idAlarme } = notification;
            if (!idAlarme) return this.marcarLida(notification); // sem alarme, só marca como lida
            this.$swal.fire({
                title: 'Carregando...',
                onBeforeOpen: () => {
                    this.$swal.showLoading();
                },
                allowOutsideClick: false,
            });
            if (!noToggle) this.toggle();

            try {
                await this.marcarLida(notification);
                const alarme = (await axios.get(api.v1.alarme.get(idAlarme))).data;
                const area = (await axios.get(api.v1.area.getPath([alarme.idArea]))).data.pop().path.join(' / ');
                const badge = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="red" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-alert-triangle"><path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path><line x1="12" y1="9" x2="12" y2="13"></line><line x1="12" y1="17" x2="12.01" y2="17"></line></svg>';
                const [ extraFull, extraValue, extraTitle ] = (alarme.extra || '').match(/^(.+)\s+\((.+)\)$/) || ['', '', ''];
                let extra = extraFull ? `<tr><td>${ extraTitle.charAt(0).toUpperCase() + extraTitle.slice(1) }</td><td>${ extraValue || extraFull || '-' }</td></tr>` : '';
                if (alarme.idDetector && extra) {
                    extra += '<tr><td>Medição atual</td><td><span id="updateable-measurement-notification">...</span></td></tr>';
                    startMeasurement(alarme.idDetector);
                }
                this.$swal.fire({
                    title: `Informações sobre a notificação`,
                    html:`<table class="table table-sm">
                            <tr><td>Data/hora inicial</td><td>${ new Date(alarme.dataHoraInicial).toLocaleString() }</td></tr>
                            <tr><td>Data/hora final</td><td>${ alarme.dataHoraFinal ? new Date(alarme.dataHoraFinal).toLocaleString(): '-' }</td></tr>
                            <tr><td>Tipo</td><td>${ alarme.severidade } de ${ notification.classe.toLowerCase() }</td></tr>
                            <tr><td>Data/hora reconhecimento</td><td>${ alarme.dataHoraReconhecimento ? new Date(alarme.dataHoraReconhecimento).toLocaleString(): '-' }</td></tr>
                            <tr><td>Tag</td><td>${ alarme.id }</td></tr>
                            <tr><td>Descrição</td><td>${ alarme.descricao }</td></tr>
                            <tr><td>${alarme.idConversor ? badge : ''} Conversor</td><td>${ alarme.nomeConversor || '-' }</td></tr>
                            <tr><td>${alarme.idConcentrador ? badge : ''} Concentrador</td><td>${ alarme.nomeConcentrador || '-' }</td></tr>
                            <tr><td>${alarme.idDetector ? badge : ''} Detector</td><td>${ alarme.nomeDetector || '-' }</td></tr>
                            <tr><td>Área</td><td>${ area }</td></tr>
                            ${extra}
                        </table>`,
                    showCancelButton: true,
                    confirmButtonText: 'Alarmes on-line',
                    confirmButtonColor: '#ffc107',
                    cancelButtonText: 'Fechar',
                    cancelButtonColor: '#6c757d',
                }).then(res => {
                    stopMeasurement();
                    if (res.value) this.$router.replace('/alarmesonline');
                });
            } catch (err) {
                this.$swal.close();
                this.$snotify.error('Erro ao carregar os detalhes da notificação: '+err.toString());
            }
        },
        async setAllLidas() {
            this.count -= (await axios.put(api.v1.notificacao.setLidas)).data;
            this.notificacoes.forEach((n) => {
                n.lido = true;
            });
            this.$emit('count', this.count);
        },

        openWS(token) {
            this.closeWS();
            this.ws = new Sockette(api.socket.notificacoes(token), {
                onmessage: (ev) => {
                    if (ev.data === 'nova notificação') {
                        this.update();
                        this.load(true);
                    }
                },
                onreconnect: () => {
                    this.update();
                    this.load(true);
                },
            });
        },
        closeWS() {
            if (this.ws) this.ws.close();
            this.ws = null;
        },

        onLoginChange() {
            this.closeWS();
            this.hidden = true;
            this.dataNotificacoes = 0;
            this.notificacoes = [];
            const token = loginService.getBearerToken();
            if (token) {
                // se tiver logado
                this.update();
                this.load();
                this.openWS(token);
            }
        },
    },
    created() {
        funcaoLogin = this.onLoginChange.bind(this);
        funcaoNotificacao = this.showDetails.bind(this);
        funcaoToggle = this.toggle.bind(this);
        this.$root.$on('loginChange', funcaoLogin);
        this.$root.$on('show-notification-details', funcaoNotificacao);
        this.onLoginChange();
    },
    beforeDestroy() {
        this.closeWS();
        this.$root.$off('loginChange', funcaoLogin);
        this.$root.$off('show-notification-details', funcaoNotificacao)
    },
};
</script>

<style scoped lang="scss">
$azul-tcs: #6dcff6;
h4 {
    margin: 0;
}

.titulo {
    border-bottom: 1px solid lightgray;
    background-color: white;
    box-shadow: 0px 3px 3px lightgray;
    line-height: 24px;
    margin: 8px 0 0 0;
    padding: 8px 8px 16px 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.notificacoes {
    background-color: white;
    position: fixed;
    width: 400px;
    height: 80vh;
    right: 0;
    top: 64px;
    z-index: 2;
    border-radius: 0 0 10px 10px;
    color: black;
    box-shadow: -0.5rem 0.5rem 1rem rgba(0, 0, 0, 0.15);

    .my-close-btn {
        display: none;
    }

    @media screen and (max-width: 790px) {
        width: 100vw;
        height: 100vh;
        right: 0;
        top: 0;

        .my-close-btn {
            display: block;
            margin-top: -3px;
        }
    }
}

.conteudo-container {
    position: relative;
    height: calc(100% - 66px);

    &.vazio {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .nenhuma-notificacao {
            color: #666;
            p {
                font-size: 1.2rem;
            }
        }
        .loader {
            position: absolute;
            top: 8px;
        }
    }
}
.conteudo {
    position: relative;
    padding: 8px;
    padding-right: 15px;
    overflow-y: scroll;
    height: 100%;

    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
    }

    &:hover {
        padding-right: 8px;

        &::-webkit-scrollbar {
            width: 10px;
        }
    }
}

/* Spinner CSS */
.loader,
.loader:before,
.loader:after {
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation-fill-mode: both;
    animation: load7 1.8s infinite ease-in-out;
}

.loader {
    color: $azul-tcs;
    font-size: 5px;
    margin: -8px auto 16px auto;
    position: relative;
    text-indent: -9999em;
    transform: translateZ(0);
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    content: '';
    position: absolute;
    top: 0;
}

.loader:before {
    left: -3.5em;
    animation-delay: -0.32s;
}

.loader:after {
    left: 3.5em;
}

@keyframes load7 {
    0%,
    80%,
    100% {
        box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
        box-shadow: 0 2.5em 0 0;
    }
}
</style>
