<template>
    <nav class="navbar fixed-top pl-0">
        <div class="d-flex justify-content-between align-items-center w-100">
            <!-- Parte esquerda e central -->
            <div class="navbar-left d-flex align-items-center h-100">
                <!-- Trigger menu lateral e nome do sistema -->
                <div
                    class="user-select-none init py-1 d-flex justify-content-center align-items-center"
                    :class="showBackButton ? 'mr-0 ' : 'mr-3'"
                    style="min-height: 70px"
                    ref="navbar-header"
                >
                    <transition name="zoom-x">
                        <div
                            class="toggle-menu p-3 h-100 d-flex justify-content-center align-items-center"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Mostrar/ocultar barra lateral"
                            @click="menuButtonClicked"
                            v-if="usuario"
                        >
                            <MenuIcon color="white" />
                        </div>
                    </transition>
                    <div class="d-flex align-items-center spaced brand">
                        <h1 class="mb-0 logo">TCS</h1>
                        <div class="vertical-line mx-2"></div>
                        <img src="../assets/img/MMI_UTILITIES_BRANCO.svg" alt="MMI" style="max-height: 48px; margin-bottom: 4px;">
                    </div>
                </div>

                <!-- Botão de voltar -->
                <div
                    class="user-select-none go-back px-2 mr-2 d-flex justify-content-center align-items-center"
                    data-cy="Voltar"
                    @click="backButtonClicked"
                    title="Voltar"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    :class="{'force-hidden': !showBackButton}"
                >
                    <ArrowLeftIcon color="#6dcff6" />
                </div>

                <!-- Título da página -->
                <h2 class="mb-0 secao" data-cy="Titulo-pagina">{{ titulo }}</h2>
            </div>

            <!-- Parte direita -->

            <!-- Versão e manutenção, apenas no login -->
            <div class="h-100 mr-2 px-2" v-if="!usuario && $route.name === 'login'">
                <div class="text-center text-primary pr-2 mr-2 manutencao-badge" v-if="manutencao">
                    Manutenção agendada
                    <br />
                    <strong>{{manutencao}}</strong>
                </div>
                <ul tabindex="1" class="btn btn-light px-2 versao-badge" @click="changelog = true" @keypress="changelog = true" title="Changelog" data-toggle="tooltip" data-placement="bottom">
                    <li>WEB: {{ versaoWeb }}</li>
                    <li>API: {{ versaoApi }}</li>
                </ul>
            </div>

            <!-- Parte direita versão desktop -->
            <div class="navbar-right d-flex align-items-center h-100 pr-2 py-1 nav-right-desktop" v-if="usuario">
                <button
                    class="notifications-container mr-3 p-0 b-0 btn"
                    @click.stop="toggleNotifications"
                    data-toggle="tooltip"
                    data-placement="left"
                    title="Notificações (Alt + N)"
                >
                    <BellIcon class="notifications" data-cy="Notificações" accesskey="n" />
                    <div
                        class="notifications-count"
                        v-if="notificationCount"
                    >{{notificationCount > 99 ? '++' : notificationCount}}</div>
                </button>
                <div
                    class="user p-1 d-flex"
                    :class="{'cursor-pointer': usuario }"
                    @click="goToProfile"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Perfil"
                >
                    <div class="avatar mr-2 text-white">
                        <UserIcon name="user" label="Ícone de Usuário" />
                    </div>

                    <div class="d-flex align-items-center">
                        <div class="wrapper">
                            <p class="name mb-0">
                                <strong>{{usuario || 'Desconectado'}}</strong>
                            </p>
                            <p class="role mb-0">{{cargo || '-'}}</p>
                        </div>
                    </div>
                </div>
                <router-link
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Sair"
                    class="user p-3 d-flex"
                    style="color: inherit;"
                    :to="{ name: 'logout',  params: { next: $route } }"
                >
                    <LogOutIcon />
                </router-link>
            </div>

            <!-- Parte direita versão mobile -->
            <div
                class="more mr-3"
                @click="toggleCollapsed"
                aria-label="Mostrar mais"
                :aria-expanded="!collapsed"
                v-if="usuario"
            >
                <MoreHorizontalIcon />
            </div>
        </div>

        <!-- Barra da direita mobile -->
        <div class="w-100 py-2 px-3 collapsed-menu justify-content-between align-items-center" v-show="!collapsed">
            <button class="ml-2 notifications-container b-0 p-0 btn" @click.stop="toggleNotifications(); collapsed=true;">
                <BellIcon class="notifications" />
                <div
                    class="notifications-count"
                    v-if="notificationCount"
                >{{notificationCount > 99 ? '++' : notificationCount}}</div>
            </button>

            <router-link
                data-toggle="tooltip"
                data-placement="bottom"
                title="Perfil"
                class="user p-2 d-flex"
                :to="{ name: 'perfil' }"
                style="color: inherit;"
                @click.native="collapsed=true"
            >
                <UserIcon />
            </router-link>

            <router-link
                data-toggle="tooltip"
                data-placement="bottom"
                title="Sair"
                class="user p-2 d-flex"
                :to="{ name: 'logout',  params: { next: $route } }"
                style="color: inherit;"
                @click.native="collapsed=true"
            >
                <LogOutIcon />
            </router-link>
        </div>

        <!-- Modal de changelog -->
        <changelog v-if="changelog" @close="changelog = false" />
        <notifications ref="notifications" @count="c=>notificationCount = c" @close="toggleNotifications"></notifications>
    </nav>
</template>

<script>
const PLACEHOLDER_TITLE = 'MMI Utilities';
import { debounce } from '@/helpers/common';
import loginService from '../services/login';
import axios from 'axios';
import api from '../api';
import dayjs from 'dayjs';

import Notifications from './NavBar/Notifications';
import Changelog from './NavBar/Changelog.vue';

export default {
    components: {
        Notifications,
        Changelog,
    },
    props: {
        showBackButton: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            titulo: PLACEHOLDER_TITLE,
            maxWidthIntervalUpdates: 0,
            resFn: null,
            collapsed: true,
            usuario: '',
            cargo: '',
            notificationCount: 0,
            versaoWeb: process.env.VUE_APP_VERSION,
            versaoApi: '-',
            manutencao: '',
            changelog: false,
        };
    },
    methods: {
        menuButtonClicked() {
            this.$emit('menu-button-click');
        },
        backButtonClicked() {
            this.$emit('back-button-click');
        },
        toggleCollapsed() {
            this.collapsed = !this.collapsed;
        },
        setUser() {
            const user = loginService.getUser();
            this.usuario = user ? user.nome : '';
            this.cargo = user ? user.grupo : '';

            this.$emit('updateNotifications');
        },
        goToProfile() {
            if (this.usuario) this.$router.push({ name: 'perfil' });
        },
        toggleNotifications() {
            this.$refs['notifications'].toggle();
        },
    },
    watch: {
        '$route.meta.title': function (newVal) {
            this.titulo = newVal || PLACEHOLDER_TITLE;
        },
    },
    mounted() {
        this.setUser();
        this.$root.$on('loginChange', () => this.setUser());
        this.resFn = debounce(() => {
            let hWidth = getComputedStyle(
                this.$refs['navbar-header'],
                null
            ).getPropertyValue('width');
            this.$emit(
                'change-max-width',
                parseFloat(hWidth.replace('px', ''))
            );
        }, 300).bind(this);

        // atualiza maxWidth ao redimensionar a janela do navegador
        window.addEventListener('resize', this.resFn);

        // atualiza maxWidth de tempos em tempo nos primeiros minutos por causa do carregamento de fontes
        const UPDATE_INTERVAL = 5000; // ms
        const MAX_INTERVAL_UPDATES = Math.ceil(
            (1 * 60 * 1000) / UPDATE_INTERVAL
        ); // 1 minuto

        (function updateMaxWidth() {
            this.resFn();
            if (++this.maxWidthIntervalUpdates < MAX_INTERVAL_UPDATES) {
                setTimeout(updateMaxWidth.bind(this), UPDATE_INTERVAL);
            }
        }.bind(this)());

        axios.get(api.v1.info.versao).then(res => {
            this.versaoApi = res.data.api || '-';
        }, () => {
            this.versaoApi = '-';
        });

        axios.get(api.v1.info.manutencao).then(res => {
            if (res.data.timestamp) {
                this.manutencao = dayjs(res.data.timestamp * 1000).format('DD/MM/YYYY HH:mm:ss');
            }
        }, () => {});
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resFn);
    },
};
</script>


<style scoped>
.force-hidden {
    display: none !important;
}

.spaced {
    padding-left: 30px;
    padding-right: 30px;
}
.navbar .notifications,
.navbar .configs,
.navbar .apps {
    fill: none;
}
.navbar .user .avatar {
    padding-top: 7px;
}
.cursor-pointer {
    cursor: pointer;
}

.notifications-container {
    position: relative;
    cursor: pointer;
}
.notifications-count {
    position: absolute;
    background: red;
    width: 16px;
    height: 16px;
    top: -5px;
    right: -2px;
    border-radius: 50%;
    color: white;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    line-height: 16px;
}

.navbar .more {
    transition: .3s;
    cursor: pointer;
}

.navbar .more[aria-expanded="true"] {
    transform: rotate(90deg);
}

.manutencao-badge, .versao-badge {
    margin: 0;
    font-family: monospace;
    padding: 0;
    display: inline-block;
}

.manutencao-badge {
    border-right: 1px solid gray;
}
.versao-badge {
    vertical-align: initial;
}
.navbar .more {
    display: none;
}
.collapsed-menu {
    display: none;
}
.collapsed-menu svg {
    color: dimgray;
}

@media screen and (max-width: 790px) {
    .collapsed-menu {
        display: flex;
    }
    .manutencao-badge, .versao-badge {
        font-size: 10px !important;
    }
    .nav-right-desktop {
        display: none !important;
    }
    .navbar .more {
        display: block;
    }
    .navbar .brand {
        display: none !important;
    }
}

.secao {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.navbar .navbar-left {
    position: relative;
    flex: 1;
    min-width: 0;
}

</style>
