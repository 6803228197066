<template>
    <div :class="{ 'retracted': retracted }">
        <a class="nav-link" data-toggle="tooltip" data-placement="right" data-boundary="window" :title="name" :data-cy="name" @click="toggle">
            <component :is="`${icon || 'link2'}-icon`" />
            <p class="mb-0">
                {{name}}
                <span class="icon-retractable">
                    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path opacity="0.7" fill-rule="evenodd" clip-rule="evenodd" />
                    </svg>
                </span>
            </p>
        </a>
        <vue-slide-up-down class="children" :active="!retracted" @open-start="emitToggle" @open-end="unbusy" @close-end="closed">
            <div>
                <slot></slot>
            </div>
        </vue-slide-up-down>
    </div>
</template>

<style scoped>
div .icon-retractable svg path {
    d: path('M10.6 0L6 4.6L1.4 0L0 1.4L6 7.4L12 1.4L10.6 0Z');
    fill: currentColor;
    transition: d .5s ease-in-out, fill .5s ease-in-out;
}
div.retracted .icon-retractable svg path {
    d: path('M0 1.4L1.4 0L7.4 6L1.4 12L0 10.6L4.6 6L0 1.4Z');
    fill: currentColor;
}

div .children {
    border-radius: 5px 5px 10px 10px;
}

svg {
    color: white;
    fill: transparent;
}

</style>

<script>
import VueSlideUpDown from 'vue-slide-up-down'
export default {
    components: {
        VueSlideUpDown
    },
    props: {
        name: String,
        icon: String,
    },
    data () {
        return  {
            retracted: true,
            busy: false,
        }
    },
    methods: {
        toggle () {
            if (this.busy) return;
            this.busy = true;
            this.retracted = !this.retracted;
        },
        emitToggle() {
            this.$emit('toggle', (this.retracted ? 'close' : 'open'));
        },
        closed () {
            this.busy = false;
            this.emitToggle();
        },
        unbusy () {
            this.busy = false;
        }
    }
}
</script>
