<template>
  <div id="app">
    <nav-bar @menu-button-click="toggleSideBar" @change-max-width="updateSideBarMaxWidth" :showBackButton="showBackButton" @back-button-click="goBack" />
    <side-bar :hidden="sideBarHidden || noPermission" :max-width="sideBarMaxWidth" @link="sideBarLinkClicked" :links="parsedLinks" @resize="sideBarResized" />
    <router-view />
    <vue-snotify></vue-snotify>
    <div id="app-bottom-padding"></div>
  </div>
</template>

<script>
import '@/assets/css/mmi.css'
import '@/assets/css/style.css'
import '@/assets/scss/utilities.scss';

import NavBar from '@/components/NavBar.vue'
import SideBar from '@/components/SideBar.vue'

import { mapMutations } from 'vuex';
import loginService from './services/login';
import { perms } from './helpers/auth'
import { copy } from './helpers/common'

import api from '@/api';
import axios from 'axios';
import AlarmSound from './assets/sounds/alarm-sound.mp3'

let itensHistorico = 0;

const isMobile = () => window.innerWidth <= 790;
const loginLogout = ['login', 'logout', 'esqueci_senha', 'redefinir_senha'];

export default {
  name: 'App',
  components: {
    NavBar,
    SideBar
  },
  data () {
    return {
      audio: new Audio(AlarmSound),
      sideBarWidth: 0,
      renewLoginInterval: 0,
      sideBarHidden: true,
      sideBarMaxWidth: 0,
      initialized: false,
      loginChangeCallback: null,
      links: [{
        to: { name: 'sinotico' },
        name: 'Sinótico',
        icon: 'map',
        showPerms: perms(['r_sinotico']),
      },{
        to: { name: 'tendencia_online' },
        name: 'Tendência on-line',
        icon: 'line-chart',
        showPerms: perms(['r_tendencia']),
      },{
        to: { name: 'tendencia_historica' },
        name: 'Tendência histórica',
        icon: 'archive',
        showPerms: perms(['r_tendencia']),
      },{
        to: { name: 'alarmes_online' },
        name: 'Alarmes on-line',
        icon: 'alert-triangle',
        showPerms: perms(['r_relatorios']),
      },{
        to: { name: 'relatorio_alarmes' },
        name: 'Histórico de alarmes',
        icon: 'monitor',
        showPerms: perms(['r_relatorios']),
      },{
        to: { name: 'modo_manutencao' },
        name: 'Manutenção',
        icon: 'settings',
        showPerms: perms(['rw_manutencao']),
      },{
        to: { name: 'relatorio_manutencao' },
        name: 'Histórico manutenção',
        icon: 'clipboard',
        showPerms: perms(['r_relatorios', 'rw_manutencao']),
      },{
        name: 'Gerenciamento',
        icon: 'sliders',
        showPerms: perms([]),
        children: [{
          to: { name: 'areas' },
          name: 'Gestor de áreas',
          icon: 'map-pin',
          showPerms: perms(['r_gerenciamentos', 'rw_gerenciamentos']),
        }, {
          to: { name: 'conversores' },
          name: 'Gestor de conversores',
          icon: 'repeat',
          showPerms: perms(['r_gerenciamentos', 'rw_gerenciamentos']),
        }, {
          to: { name: 'concentradores' },
          name: 'Gestor de concentradores',
          icon: 'command',
          showPerms: perms(['r_gerenciamentos', 'rw_gerenciamentos']),
        },{
          to: { name: 'detectores' },
          name: 'Gestor de detectores',
          icon: 'cpu',
          showPerms: perms(['r_gerenciamentos', 'rw_gerenciamentos']),
        },
        {
          to: { name: 'usuarios' },
          name: 'Gestor de usuários',
          icon: 'user',
          showPerms: perms(['rwd_usuario']),
        },
        {
          to: { name: 'gestor_alarmes' },
          name: 'Gestor de alarmes',
          icon: 'check-square',
          showPerms: perms(['rw_gestao_alarme']),
        }]
      },{
        to: { name: 'log_login' },
        name: 'Logs de login/logout',
        icon: 'paperclip',
        showPerms: perms(['rwd_usuario']),
    }],
      parsedLinks: [],
    }
  },
  computed: {
    showBackButton () {
      let routes = ['home', ...loginLogout];
      return !routes.includes(this.$route.name);
    },
    noPermission () {
      return loginLogout.some(el => el === this.$route.name);
    }
  },
  watch: {
    noPermission() {
      this.sideBarHidden = this.noPermission;
      this.updateSideBarWidth(this.sideBarHidden || isMobile() ? 0 : this.sideBarWidth);
    }
  },
  methods: {
    goBack () {
      if (itensHistorico <= 1) {
        let found = false;
        for (let i = this.$route.matched.length - 1; i >= 0; i--) {
          let match = this.$route.matched[i];
          if (match.parent && match.parent.path+'/' !== match.path) {
            itensHistorico--;
            this.$router.push(match.parent.path);
            found = true;
            break;
          }
        }
        if (!found) this.$router.push({ name: 'home' });
      }
      else this.$router.back();
    },
    toggleSideBar () {
      if (this.noPermission) return;
      this.sideBarHidden = !this.sideBarHidden;
      this.updateSideBarWidth(this.sideBarHidden || isMobile() ? 0 : this.sideBarWidth);
    },
    sideBarResized (w) {
      this.sideBarWidth = w;
      this.updateSideBarWidth(this.sideBarHidden || isMobile() || this.noPermission ? 0 : w);
    },
    updateSideBarMaxWidth (w) {
      this.sideBarMaxWidth = w;
      if (!this.initialized) {
        // detacta celular e encolhe a barra
        this.sideBarHidden = w < 100;
        this.initialized = true;
        this.sideBarWidth = 72;
        this.updateSideBarWidth(this.sideBarHidden || isMobile() || this.noPermission ? 0 : this.sideBarWidth);
      }
    },
    sideBarLinkClicked () {
      if (isMobile())
        this.sideBarHidden = !this.sideBarHidden;
    },
    _updateLinks (links) {
      for (let link of links) {
        if (link.children) {
          link.children = this._updateLinks(link.children);
        }
      }
      return links.filter(ln => ln.showPerms
                              ? loginService.verifyPermissions(ln.showPerms) && (!ln.children || ln.children.length > 0)
                              : true);
    },
    updateLinks () {
      let links = copy(this.links)
      this.parsedLinks = this._updateLinks(links);
    },
    ...mapMutations(['updateSideBarWidth']),

    makeAlarmSound() {
      if (localStorage.getItem('utilities/sinotico/som') === 'true') this.audio.play();
    },
    async getAlarmes() {
      if (localStorage.getItem('utilities/sinotico/som') === 'true') {
        const res = await axios.get(api.v1.alarme.online(1, -1, '{}', 'dataHoraInicial', false));
        const gasAlarmLogs = res.data.rows.filter(l => {
          return l.classeAlarme === 'Vazamento de gás' && l.severidade === 'Alarme' && l.dataHoraReconhecimento === null;
        });

  
        if (gasAlarmLogs.length) {
          this.makeAlarmSound();
          setTimeout(this.getAlarmes.bind(this), 10000);
          return;
        }
      }

      setTimeout(this.getAlarmes.bind(this), 30000);
    },
  },
  created () {
    this.updateLinks();
    this.loginChangeCallback = this.updateLinks.bind(this);
    this.$root.$on('loginChange', this.loginChangeCallback);

    this.$router.afterEach((to, from) => {
      if (from.name === null || to.name === 'home') itensHistorico = 0;
      else if (!(loginLogout.includes(from.name) || loginLogout.includes(to.name)))
        itensHistorico++;
    });

    this.renewLoginInterval = setInterval(() => {
      loginService.renewToken();
    }, 10 * 60 * 1000); // 10 minutos

    /* === ATENÇÃO ===
     * Para que a autenticação funcione adequadamente, APENAS NESTE COMPONENTE
     * é necessário que TODAS as requisições sejam feitas dentro deste
     * `setTimeout([function], 0)`. Isso é para certificar que o router já
     * tenha sido carregado no momento do início da requisição. Isso é crucial
     * para uma funcionalidade da autenticação.
     * Além disso, também é necessário que PELO MENOS UMA requisição seja feita
     * dentro deste `setTimeout([function], 0)` para assegurar uma verificação
     * inicial da validade da sessão do usuário. É recomendado que seja chamada
     * a função `loginService.validateToken` ou `loginService.renewToken`.
    */
    setTimeout(async () => {
      await loginService.validateToken();
      this.getAlarmes();
    }, 0);

    // Para permitir sair da aplicação usando o link direto para a rota de
    // logout é necessário adiar a renovação inicial do token.
    setTimeout(() => {
      loginService.renewToken();
    }, 1 * 60 * 1000); // 1 minuto
  },
  beforeDestroy () {
      this.$root.$off('loginChange', this.loginChangeCallback);
      clearInterval(this.renewLoginInterval);
  }
}
</script>

<style scoped>
  #app-bottom-padding {
      height: 80px;
  }
  @media (max-width: 790px) {
    #app-bottom-padding {
      max-height: 0px;
    }
  }
</style>
