/**
 * Copia por referência os valores de um objeto para outro e retorna este outro
 * @param {any} source objeto de origem
 * @param {Array<String|Number>} params array de parâmetros a serem copiados. ex.: ['id', 'nome', 'idade', 0]
 *
 */
export const copyProps = source => params => params.reduce((acc, key) => { acc[key] = source[key]; return acc }, {});

/**
 * Copia por valor (JSON parte and stringify) um objeto
 * @param {any} o objeto de origem
 */
export const copy = o => JSON.parse(JSON.stringify(o));

/**
 * Copia por valor (JSON parse and stringify) os valores de um objeto para outro e retorna este outro
 * @param {any} s objeto de origem
 * @param {Array<String|Number>} p array de parâmetros a serem copiados. ex.: ['id', 'nome', 'idade', 0]
 */
export const hardCopyProps = source => params => params.reduce((acc, key) => { acc[key] = copy(source[key]); return acc }, {});

/**
 * Acessa propriedade profundas dentro de um objeto com checagem de null
 * @param {any[]} p vetor de path. ex.: ['user', 0, 'comments']
 * @param {any} o object
 *
 * @type {function(any[]): function(any): any}
 */
export const deep = p => o => p.reduce((acc, idx) => (acc && !(acc[idx] == null)) ? acc[idx] : null, o);

/**
 * Acessa propriedade profundas dentro de um objeto com checagem de null, com _dot notation_
 * @param {String} p path separado por pontos . ex.: 'user.0.comments'
 */
export const ddeep = p => deep(p.split('.'));

/**
 * Retorna uma promessa que resolve após `ms` milissegundos. Internamente, essa função usa `setTimeout`.
 * @param {Number} ms milissegundos
 */
export const wait = ms => new Promise(res => setTimeout(res, ms));


// Stringify functions

export const stringifyComponente = c => `${c.id} - ${c.nome} (${c.tipo})`;
export const stringifyTipoDado = t => `${t.nome} (${t.dominio})`;
export const stringifyMedidor = m => `${m.nome} (${m.uuid.split('-')[0]}-...)`;

/**
 * Função de debounce. Para utilizar o this do Vue dentro da função 'debounced', você DEVE fazer um bind para this
 * da seguinte forma: `debounce(function(){ ... }).bind(this)` ou `debounce(() => { ... }).bind(this)`.
 */
export const debounce = require('tiny-debounce')

export const TRY_TIMEOUT = 4000; // ms
