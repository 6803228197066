import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import SubRouter from './views/SubRouter.vue'

import loginService from './services/login'
import { perms } from './helpers/auth'

Vue.use(Router)

import NProgress from 'nprogress';

const BASE_TITLE = "TCS MMI Utilities"
const TITLE_SEPARATOR = " | "


let router = new Router({
  mode: 'history',
  scrollBehavior (/* to, from, savedPosition */) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        perms: perms([]),
      },
    },
    {
      path: '/sinotico',
      name: 'sinotico',
      meta: {
        title: 'Tela de sinótico',
        perms: perms(['r_sinotico']),
      },
      component: () => import(/* webpackChunkName: "sinotico" */ './views/Sinotico.vue')
    },
    {
      path: '/tendenciaonline',
      name: 'tendencia_online',
      meta: {
        title: 'Tendência on-line',
        perms: perms(['r_tendencia']),
      },
      component: () => import(/* webpackChunkName: "tendencia_online" */ './views/TendenciaOnline.vue')
    },
    {
      path: '/tendenciahistorica',
      name: 'tendencia_historica',
      meta: {
        title: 'Tendência histórica',
        perms: perms(['r_tendencia']),
      },
      component: () => import(/* webpackChunkName: "tendencia_historica" */ './views/TendenciaHistorica.vue')
    },
    {
      path: '/alarmesonline',
      name: 'alarmes_online',
      meta: {
        title: 'Alarmes on-line',
        perms: perms(['r_relatorios']),
      },
      component: () => import(/* webpackChunkName: "alarmes_online" */ './views/AlarmesOnline.vue')
    },
    {
      path: '/relatorioalarmes',
      name: 'relatorio_alarmes',
      meta: {
        title: 'Histórico de alarmes',
        perms: perms(['r_relatorios']),
      },
      component: () => import(/* webpackChunkName: "relatorio_alarmes" */ './views/RelatorioAlarmes.vue')
    },
    {
      path: '/manutencao',
      name: 'modo_manutencao',
      meta: {
        title: 'Manutenção',
        perms: perms(['rw_manutencao']),
      },
      component: () => import(/* webpackChunkName: "modo_manutencao" */ './views/Manutencao.vue')
    },
    {
      path: '/relatoriomanutencao',
      name: 'relatorio_manutencao',
      meta: {
        title: 'Histórico de manutenção',
        perms: perms([['r_relatorios'], ['rw_manutencao']]),
      },
      component: () => import(/* webpackChunkName: "relatorio_manutencao" */ './views/RelatorioManutencao.vue')
    },
    {
      path: '/login',
      name: 'login',
      meta: {
        title: 'Login',
      },
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/revalidate',
      name: 'revalidar_login',
      meta: {
        title: 'Reautenticação necessária',
      },
      component: () => import(/* webpackChunkName: "revalidar_login" */ './views/RevalidacaoLogin.vue')
    },
    {
      path: '/forgotpassword',
      name: 'esqueci_senha',
      meta: {
        title: 'Recuperação de senha',
      },
      component: () => import(/* webpackChunkName: "esqueci_senha" */ './views/EsqueciSenha.vue')
    },
    {
      path: '/redefinirsenha/:token?',
      name: 'redefinir_senha',
      meta: {
        title: "Redefinir senha",
      },
      component: () => import(/* webpackChunkName: "redefinirsenha" */ './views/RedefinirSenha.vue'),
    },
    {
      path: '/logout',
      name: 'logout',
      meta: {
        title: 'Logout',
      },
      component: () => import(/* webpackChunkName: "login" */ './views/Login.vue')
    },
    {
      path: '/perfil',
      name: 'perfil',
      meta: {
        title: 'Perfil',
        perms: perms([]),
      },
      component: () => import(/* webpackChunkName: "perfil" */ './views/Perfil.vue')
    },
    {
      path: '/areas',
      meta: {
        title: "Áreas",
      },
      component: SubRouter,
      children: [
        {
          path: '',
          name: 'areas',
          meta: {
            title: "Gestor de áreas",
            perms: perms([['r_gerenciamentos'], ['rw_gerenciamentos']]),
          },
          component: () => import(/* webpackChunkName: "areas" */ './views/Areas/List.vue'),
        },
      ]
    },
    {
      path: '/conversores',
      meta: {
        title: "Conversores",
      },
      component: SubRouter,
      children: [
        {
          path: '',
          name: 'conversores',
          meta: {
            title: "Gestor de conversores",
            perms: perms([['r_gerenciamentos'], ['rw_gerenciamentos']]),
          },
          component: () => import(/* webpackChunkName: "conversores" */ './views/Conversores/List.vue'),
        },
        {
          path: 'add',
          name: 'add_conversor',
          meta: {
            title: "Adicionar conversor",
            perms: perms(['rw_gerenciamentos']),
          },
          component: () => import(/* webpackChunkName: "conversores" */ './views/Conversores/Edit.vue'),
        },
        {
          path: 'edit/:id',
          name: 'edit_conversor',
          meta: {
            title: "Editar conversor",
            perms: perms(['rw_gerenciamentos']),
          },
          component: () => import(/* webpackChunkName: "conversores" */ './views/Conversores/Edit.vue'),
        }
      ]
    },
    {
      path: '/concentradores',
      meta: {
        title: "Concentradores",
      },
      component: SubRouter,
      children: [
        {
          path: '',
          name: 'concentradores',
          meta: {
            title: "Gestor de concentradores",
            perms: perms([['r_gerenciamentos'], ['rw_gerenciamentos']]),
          },
          component: () => import(/* webpackChunkName: "concentradores" */ './views/Concentradores/List.vue'),
        },
        {
          path: 'add',
          name: 'add_concentrador',
          meta: {
            title: "Adicionar concentrador",
            perms: perms(['rw_gerenciamentos']),
          },
          component: () => import(/* webpackChunkName: "concentradores" */ './views/Concentradores/Edit.vue'),
        },
        {
          path: 'edit/:id',
          name: 'edit_concentrador',
          meta: {
            title: "Editar concentrador",
            perms: perms(['rw_gerenciamentos']),
          },
          component: () => import(/* webpackChunkName: "concentradores" */ './views/Concentradores/Edit.vue'),
        }
      ]
    },
    {
      path: '/detectores',
      meta: {
        title: "Detectores",
      },
      component: SubRouter,
      children: [
        {
          path: '',
          name: 'detectores',
          meta: {
            title: "Gestor de detectores",
            perms: perms([['r_gerenciamentos'], ['rw_gerenciamentos']]),
          },
          component: () => import(/* webpackChunkName: "detectores" */ './views/Detectores/List.vue'),
        },
      ]
    },
    {
      path: '/usuarios',
      meta: {
        title: "Usuários",
      },
      component: SubRouter,
      children: [
        {
          path: '',
          name: 'usuarios',
          meta: {
            title: "Gestor de usuários",
            perms: perms(['rwd_usuario']),
          },
          component: () => import(/* webpackChunkName: "usuarios" */ './views/Usuarios/List.vue'),
        },
        {
          path: 'add',
          name: 'add_usuario',
          meta: {
            title: "Adicionar usuário",
            perms: perms(['rwd_usuario']),
          },
          component: () => import(/* webpackChunkName: "usuarios" */ './views/Usuarios/Edit.vue'),
        },
        {
          path: 'edit/:id',
          name: 'edit_usuario',
          meta: {
            title: "Editar usuário",
            perms: perms(['rwd_usuario']),
          },
          component: () => import(/* webpackChunkName: "usuarios" */ './views/Usuarios/Edit.vue'),
        },
        {
          path: 'grupos',
          meta: {
            title: "Grupos de usuários",
          },
          component: SubRouter,
          children: [
            {
              path: '',
              name: 'gruposusuarios',
              meta: {
                title: "Gestor de grupos de usuários",
                perms: perms(['rwd_usuario']),
              },
              component: () => import(/* webpackChunkName: "gruposusuarios" */ './views/GruposUsuarios/List.vue'),
            },
            {
              path: 'add',
              name: 'add_grupousuario',
              meta: {
                title: "Adicionar grupo de usuários",
                perms: perms(['rwd_usuario']),
              },
              component: () => import(/* webpackChunkName: "gruposusuarios" */ './views/GruposUsuarios/Edit.vue'),
            },
            {
              path: 'edit/:id',
              name: 'edit_grupousuario',
              meta: {
                title: "Editar grupo de usuários",
                perms: perms(['rwd_usuario']),
              },
              component: () => import(/* webpackChunkName: "gruposusuarios" */ './views/GruposUsuarios/Edit.vue'),
            }
          ]
        },
      ]
    },
    {
      path: '/gestoralarmes',
      name: 'gestor_alarmes',
      meta: {
        title: "Gestor de alarmes",
        perms: perms(['rw_gestao_alarme']),
      },
      component: () => import(/* webpackChunkName: "gestoralarmes" */ './views/GestorAlarmes.vue'),
    },
    {
      path: '/logs',
      name: 'Logs',
      component: SubRouter,
      children: [
        {
          path: 'login',
          name: 'log_login',
          meta: {
            title: "Logs de login/logout",
            perms: perms(['rwd_usuario']),
          },
          component: () => import(/* webpackChunkName: "logs" */ './views/Logs/LogLogin.vue'),
        }
      ],
    },

    // ERROS

    {
      name: '403',
      path: '/forbidden',
      meta: {
        title: '403 - Permissão insuficiente',
      },
      component: () => import(/* wepbackChunkName: "erros" */ './views/403.vue'),
    },
    // 404 (precisa ser a última rota deste arquivo)
    {
      name: '404',
      path: "*",
      meta: {
        title: '404 - Página não encontrada',
      },
      component: () => import(/* webpackChunkName: "erros" */ './views/404.vue'),
    },
  ]
})

router.beforeEach((to, from, next) => {
  // Altera título da janela de acordo com a página acessada
  document.title = (to.meta.title ? to.meta.title + TITLE_SEPARATOR : '') + BASE_TITLE

  // Controle de permissões de acesso
  if (!to.meta.perms) next(); // página pública
  else if (!loginService.isLoggedIn()) next({ name: 'login', params: { next: to }, replace: true }); // não logado
  else if (!loginService.verifyPermissions(to.meta.perms)) next({ name: '403', params: { next: to, prev: from }, replace: true }); // sem permissão
  else next(); // acesso permitido
})

// tooltips!
router.afterEach(() => {
  const $ = require('jquery');
  $('.tooltip[role=tooltip]').tooltip('hide');
  setTimeout(() => {
    const $tooltips = $('[data-toggle="tooltip"]');
    if (window.innerWidth <= 790) $tooltips.tooltip('disable');
    else $tooltips.tooltip('enable');
  });
})

// loading
NProgress.configure({ trickleRate: 0.1, trickleSpeed: 800, showSpinner: false });
router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})
router.afterEach(() => {
  NProgress.done()
})

export default router
