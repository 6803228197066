<template>
    <div
        class="mensagem"
        :class="{
            lida: source.lido,
            critico: source.severidade === 'Crítico',
            alarme: source.severidade === 'Alarme',
            alerta: source.severidade === 'Alerta',
        }"
        @click="$root.$emit('show-notification-details', source)"
    >
        <div class="header">
            <div class="tipo">{{ source.classe }}</div>
            <div class="data">{{ source.dataHora }}</div>
        </div>
        <div class="body">{{ source.mensagem }}</div>
    </div>
</template>

<script>
export default {
    name: 'item-component',
    props: {
        index: {
            // index of current item
            type: Number,
        },
        source: {
            // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default() {
                return {};
            },
        },
    },
};
</script>

<style lang="scss" scoped>
$azul-tcs: #6dcff6;
.mensagem {
    padding: 4px;
    margin-bottom: 6px;
    min-height: 80px;
    cursor: pointer;

    .header {
        display: flex;
        justify-content: space-between;
        color: #333;
        font-size: 0.9rem;
        margin-bottom: 4px;

        .tipo::before {
            content: ' ';
            display: inline-block;
            background-color: $azul-tcs;
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            margin-right: 0.3rem;
        }
    }

    &:hover {
        background-color: rgba(116, 236, 116, 0.1);
    }

    &.lida {
        color: #777;

        &:hover {
            background-color: rgba(147, 158, 147, 0.1);
        }

        .header {
            color: #777;
            .tipo::before {
                background-color: #777 !important;
            }
        }
    }

    &.critico .header .tipo::before {
        background-color: darkred;
    }
    &.alarme .header .tipo::before {
        background-color: red;
    }
    &.alerta .header .tipo::before {
        background-color: orange;
    }
}

</style>
