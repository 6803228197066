const dataTables = ['Area', 'Conversor', 'Concentrador', 'Detector', 'Usuario', 'GrupoUsuario', 'HistoricoAlarme', 'LogLogin'];

function genState () {
    let obj = {};
    for (let dataTable of dataTables) {
        obj['dataTable'+dataTable] =  {
            sortBy: 0,
            sortAsc: true,
            query: ''
        };
    }
    return obj;
}
function genMutations () {
    let obj = {};
    for (let dataTable of dataTables) {
        obj['setStateDataTable'+dataTable] = function setStateDataTable (state, sort) {
            state['dataTable'+dataTable] = sort;
        };
    }
    return obj;
}
export default {
    namespaced: true,
    state: genState(),
    mutations: genMutations(),
}
