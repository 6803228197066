import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import dataTables from './modules/dataTables';

const CACHE_LIMIT = 60*1000;

const store = new Vuex.Store({
    strict: process.env.NODE_ENV === 'development',
    modules: {
        dataTables
    },
    state: {
        fontScale: +(localStorage.getItem('utilities/sinotico/fonte')) || 1,
        sideBarWidth: 0,
        cacheAreaHierarquica: new Map(),
    },
    mutations: {
        updateSideBarWidth (state, w) {
            state.sideBarWidth = w;
        },
        addAreaHierarquica (state, { idArea, nomeArea } = {}) {
            state.cacheAreaHierarquica.set(idArea, { nome: nomeArea, data: Date.now() });
        },
        setFontScale (state, scale) {
            if (!+scale) return;
            if (scale > 3) scale = 3;
            if (scale < 1) scale = 1;
            localStorage.setItem('utilities/sinotico/fonte', scale);
            state.fontScale = scale;
        }
    },
    getters: {
        getAreaHierarquica: state => idArea => {
            const cache = state.cacheAreaHierarquica.get(idArea);
            if(cache && Date.now() - cache.data < CACHE_LIMIT) {
                return cache.nome;
            } else {
                return undefined;
            }
        }
    }
});

export default store;
