import buildUrl from 'build-url';

import configurable_base_url from '@/api_base_url.json';

let BASE_URL = configurable_base_url || `${document.location.protocol}//${document.location.hostname}:3000/api`; //'http://10.0.0.207:3000/api';
if (BASE_URL.startsWith('/')) BASE_URL = `${document.location.protocol}//${document.location.host}${BASE_URL}`;

const listApi = url => ({
    list (page, results, q, order, asc) {
        return buildUrl(url, {
            path: 'list',
            queryParams: { page, results, q, order, asc: +!(asc === false) },
        });
    }
});

const basic = url => ({
    _url: url,
    ...listApi(url),
    get (id) {
        return url + '/get/' + id;
    },
    get create () {
        return url + '/create';
    },
    get update () {
        return url + '/update';
    },
    get destroy () {
        return url + '/destroy';
    },
});

export default Object.freeze({
    baseURL: BASE_URL,
    get v1 () {
        return {
            _url: this.baseURL + '/v1',
            get info () {
                const _url = this._url + '/info';
                return {
                    get versao () {
                        return _url + '/versao';
                    },
                    get timestamp () {
                        return _url + '/timestamp';
                    },
                    get manutencao () {
                        return _url + '/manutencao';
                    },
                }
            },
            get area () {
                const _url = this._url + '/area';
                return {
                    ...basic(_url),
                    getPath (areas) {
                        return _url + '/get-path?areas='+JSON.stringify(areas);
                    },
                    listRecursive (q) {
                        return _url + '/list-recursive?q='+encodeURIComponent(q);
                    },
                    listRecursiveByIds (areas) {
                        return _url + '/list-recursive?areas='+JSON.stringify(areas);
                    },
                };
            },
            get conversor () {
                const _url = this._url + '/conversor';
                return {
                    ...basic(_url),
                    listByAreas(areas) {
                        return _url + '/list-by-areas?areas=' + JSON.stringify(areas);
                    },
                }
            },
            get tipoConversor () {
                const _url = this._url + '/tipo-conversor';
                return listApi(_url);
            },
            get concentrador () {
                const _url = this._url + '/concentrador';
                return {
                    ...basic(_url),
                    list (page, results, q, order, asc, recNZoom=false) {
                        return buildUrl(_url, {
                            path: 'list',
                            queryParams: { page, results, q, order, asc: +!(asc === false), recNZoom },
                        });
                    },
                    listByAreas(areas) {
                        return _url + '/list-by-areas?areas=' + JSON.stringify(areas);
                    },
                    get updateCalibracao() {
                        return _url + '/update-calibracao';
                    },
                }
            },
            get tipicoConcentrador () {
                const _url = this._url + '/tipico-concentrador';
                return {
                    ...listApi(_url),
                    get (id) {
                        return _url + '/get/' + id;
                    },
                }
            },
            get detector () {
                const _url = this._url + '/detector';
                return {
                    ...basic(_url),
                    listByAreas(areas) {
                        return _url + '/list-by-areas?areas=' + JSON.stringify(areas);
                    },
                    get updateCalibracao() {
                        return _url + '/update-calibracao';
                    },
                };
            },
            get perfil () {
                const _url = this._url + '/usuario/self';
                return {
                    get: _url,
                    update: _url,
                }
            },
            get usuario () {
                const _url = this._url + '/usuario';
                return {
                    ...basic(_url),
                    list (page, results, q, order, asc, inativos) {
                        return buildUrl(_url, {
                            path: 'list',
                            queryParams: { page, results, q, order, asc: +!(asc === false), inativos },
                        });
                    }
                };
            },
            get password () {
                const _url = this._url + '/password';
                return {
                    _url,
                    get forgot () {
                        return this._url + '/forgot';
                    },
                    get reset () {
                        return this._url + '/reset';
                    },
                }
            },
            get gruposUsuarios () {
                const _url = this._url + '/grupoUsuario';
                return basic(_url);
            },
            get permissao () {
                const _url = this._url + '/permissao';
                return {
                    _url,
                    get list () {
                        return this._url + '/list';
                    },
                    get (id) {
                        return this._url + '/get/' + id;
                    },
                };
            },
            get uuid () {
                return this._url + '/uuid';
            },
            get auth () {
                const _url = this._url + '/auth';
                return {
                    _url,
                    get login () {
                        return this._url + '/login';
                    },
                    get verify () {
                        return this._url + '/gen204';
                    },
                    get renew () {
                        return this._url + '/renew';
                    },
                }
            },
            get medicao () {
                const _url = this._url +'/medicao';
                return {
                    _url,
                    get list () {
                        return this._url + '/list';
                    },
                    get (id) {
                        return this._url + '/get/' + id;
                    },
                };
            },
            get alarme () {
                const _url = this._url + '/alarme';
                return {
                    ...listApi(_url),
                    get (id) {
                        return _url + '/get/' + id;
                    },
                    download(q) {
                        return _url + '/download?q=' + encodeURIComponent(q);
                    },
                    online(page, results, q, asc) {
                        return buildUrl(_url, {
                            path: 'online',
                            queryParams: { page, results, q, asc: +!(asc === false) },
                        });
                    },
                    reconhecer (id) {
                        return _url + '/acknowledge/' + id;
                    },
                }
            },
            get classeAlarme () {
                return this._url + '/classeAlarme/list';
            },
            get statusAlarme () {
                const _url = this._url + '/statusAlarme';
                return {
                    get list () {
                        return _url + '/list';
                    },
                    get (idClasseAlarme, severidade) {
                        return _url + '/get/' + idClasseAlarme + '/' + severidade;
                    },
                    get activate () {
                        return _url + '/activate';
                    },
                    get deactivate () {
                        return _url + '/deactivate';
                    },
                };
            },
            get manutencao () {
                const _url = this._url + '/manutencao';
                return {
                    ...listApi(_url),
                    get create () {
                        return _url + '/create';
                    },
                    get reactivate () {
                        return _url + '/reactivate'
                    }
                }
            },
            get notificacao () {
                const _url = this._url + '/notificacao';
                return {
                    _url,
                    list (data) {
                        return this._url + '/list?data=' + data;
                    },
                    get naoLidas () {
                        return this._url + '/nao-lidas';
                    },
                    get setLidas () {
                        return this._url + '/set-lidas';
                    },
                    setLida (id) {
                        return this._url + '/set-lida/' + id;
                    }
                }
            },
            get graficos () {
                const _url = this._url + '/graficos';
                return {
                    _url,
                    tendencia (dataHoraInicial, dataHoraFinal, idDetectores, intervalo = undefined, numeroPontos = 300, tabela) {
                        return buildUrl(_url, {
                            path: 'tendencia',
                            queryParams: {
                                dataHoraInicial: +dataHoraInicial,
                                dataHoraFinal: +dataHoraFinal,
                                idDetectores: JSON.stringify(idDetectores),
                                intervalo,
                                numeroPontos: intervalo ? undefined : numeroPontos,
                                tabela,
                            },
                        });
                    },
                    alarmes (q) {
                        return buildUrl(_url, {
                            path: 'alarmes',
                            queryParams: { q },
                        });
                    }
                }
            },
            get logs () {
                const _url = this._url + '/logs';
                return {
                    _url,
                    login (page, results, q, order, asc) {
                        return buildUrl(_url, {
                            path: 'login',
                            queryParams: { page, results, q, order, asc: +!(asc === false) },
                        });
                    },
                    logout () {
                        return buildUrl(_url, {
                            path: 'logout',
                        });
                    }
                };
            },
            get relatorios () {
                const _url = this._url + '/relatorios';
                return {
                    _url,
                    get detectoresOffline () {
                        return this._url + '/detectores-offline';
                    }
                }
            }
        };
    },
    get socket () {
        let trueBaseURL = this.baseURL.split('://');
        if (trueBaseURL[0] === 'https') trueBaseURL[0] = 'wss';
        else trueBaseURL[0] = 'ws';

        const _url = trueBaseURL.join('://') + '/socket';
        return {
            _url,
            notificacoes (token) {
                return this._url + '/notificacoes?bearer=' + token;
            },
            medicoes (token, idDetector) {
                return this._url + '/medicoes?idDetector=' + idDetector + '&bearer=' + token;
            }
        }
    },
    get changelog () {
        return this.baseURL + '/changelog.html';
    },
})
