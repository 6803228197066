import Vue from 'vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

import 'nprogress/nprogress.css'

import iconsPlugin from './plugins/iconsPlugin';
Vue.use(iconsPlugin)

import skeleton from 'tb-skeleton'
import  'tb-skeleton/dist/skeleton.css'
Vue.use(skeleton)

import VueSweetalert2 from 'vue-sweetalert2'
Vue.use(VueSweetalert2)

import Snotify from 'vue-snotify'
import 'vue-snotify/styles/material.css'
Vue.use(Snotify)

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
Vue.component('multiselect', Multiselect)

import store from './store'

import App from './App.vue'
import router from './router'

import loginService from './services/login'

Vue.config.productionTip = false
Vue.config.devtools = true
// descomente para desativar o vue devtools em produção; comente a linha acima
// Vue.config.devtools = process.env.NODE_ENV === 'development'

const vue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

loginService.setVueInstance(vue)
