import loginService from '@/services/login';

/**
 * Retorna permissões necessárias para acessar uma página. se nenhum parâmetro é passado, nenhuma
 * permissão será necessária para acessar a página
 * @param {(string|string[])[]} [perms] array de strings de permissão necessárias para acessar a página.
 * o array de permissões é tratado como um OR. Para fazer um AND, deve-se colocar um array de permissões
 * dentro deste array.
 *
 * @example
 * // para acessar a página o usuário deve possui as permissões:
 * // ('relatório' e 'operador') ou ('auditor' e 'relatorio') ou ('admin')
 * perms([['relatorio', 'operador'], ['auditor', 'relatorio'], 'admin']);
 */
export const perms = perms => perms || false;

/**
 * Retorna uma promessa que resolve para true se o usuário possui as permissões passadas por parâmetro,
 * ou para false caso contrário exibindo um alerta para o usuário.
 * @param {(string|string[])[]|string} [permitions] uma string ou array de strings de permissão
 * necessárias para acessar a página. Caso seja passada uma string a função verifica se o usuário possui
 * a permissão, caso contrário o array de permissões é tratado como um OR. Para fazer um AND, deve-se
 * colocar um array de permissões dentro deste array.
 */
export async function verifyPermitions(permitions = false) {
    let perms = typeof permitions === 'string' ? [permitions] : permitions;
    if (loginService.verifyPermissions(perms)) {
        return true;
    }
    const { value } = await this.$swal.fire({
        title: 'Permissão insuficiente',
        text: `Você não tem permissão para acessar esta funcionalidade.`,
        type: 'info',
        showCancelButton: true,
        focusCancel: true,
        confirmButtonText: 'Trocar de usuário',
        cancelButtonText: 'Ok',
        allowOutsideClick: true,
        showCloseButton: true,
    });
    if (value) {
        this.$router.push({ name: 'logout', params: { next: this.$route } });
    }
    return false;
}
