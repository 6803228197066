'use strict'

import api from '../api'
import axios from 'axios'

/**
 * pega um array de qualquer coisa e envolve cada elemento num array (a menos que ele já seja um)
 * @param {any[]} permissions array of any
 * @return {any[][]}
 * @example
 * arrayfy([['admin'], 'baunilha', ['laranja', 'banana'], true])
 * // => [['admin'], ['baunilha'], ['laranja', 'banana'], [true]]
 */
const arrayfy = arr => arr.map(el => Array.isArray(el) ? el : [el]);
const permMatch = userPermissions => permissions => permissions.every(p => userPermissions.includes(p));

const SAFE_TIME_SECONDS = 60 * 30; // 30 minutos

class LoginManager {
  /**
   *
   * @param {Storage} storage
   * @param {string} prefix
   */
  constructor ({storage = localStorage, prefix = '_auth_', vue} = {}) {
    if (storage && storage.getItem && storage.setItem && storage.removeItem) { // verify storage
      this._storage = storage;
    } else {
      throw new Error('Invalid Storage')
    }
    this._bearer = `${prefix}bearer`;
    this._user = `${prefix}info`;
    this._vue = vue;
    this._axiosIntercepts = {
      req: null,
      res: null,
    };

    // inicialização
    let token = this._storage.getItem(this._bearer);
    this._redefineAxiosIntercepts(token);
  }

  setVueInstance(vue) {
    this._vue = vue;
  }

  doLogin (usuario, token) {
    this._storage.setItem(this._user, JSON.stringify(usuario));
    this._storage.setItem(this._bearer, token);
    this._redefineAxiosIntercepts(token);
    this._vue.$root.$emit('loginChange');
  }

  async login (email, senha, reCaptchaResponse) {
    const { error, usuario, token } = (await axios.post(api.v1.auth.login, { email, senha, reCaptchaResponse })).data;
    if (error) {
      return false;
    } else {
      this.doLogin(usuario, token);
      return true;
    }
  }


  async renewToken () {
    const oldToken = this.getBearerToken();
    if (!oldToken) return false;

    const { error, usuario, token } = (await axios.post(api.v1.auth.renew)).data;
    if (error) {
      return false;
    } else {
      this.doLogin(usuario, token);
      return true;
    }
  }

  logout () {
    this._storage.removeItem(this._bearer);
    this._storage.removeItem(this._user);
    this._redefineAxiosIntercepts(null);
    this._vue.$root.$emit('loginChange');
  }

  isLoggedIn () {
    let bearer = this._storage.getItem(this._bearer);
    return Boolean(bearer);
  }

  getUser () {
    if (!this.isLoggedIn()) return null;
    let user = this._storage.getItem(this._user);
    try {
      return JSON.parse(user);
    } catch (error) {
      this.logout();
      return null;
    }
  }

  isSafe () {
    let user = this.getUser();
    return user && (Math.floor(Date.now()/1000) - user.oit < SAFE_TIME_SECONDS);
  }

  /**
   * Retorna se a autenticação do usuário é válida
   */
  async validateToken () {
    if (!this.isLoggedIn()) return false;
    try {
      await axios.get(api.v1.auth.verify);
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Retorna se um usuário possui dadas permissões.
   *
   * - Se chamada com um booleano como parâmetro, retorna este booleano, independentemente de haver usuário logado.
   * - Se for chamada com um array vazio, retorna `true` caso haja um usuário logado.
   * - Se for chamada com uma string, retorna `true` caso haja um usuário logado e ele tenha tal permissão.
   * - Se for chamada com um array, retorna `true` caso o usuário possua todas as permissões de algum dos elementos deste array
   * (já que cada elemento pode ser uma string ou um array de strings)
   *
   * @param {boolean|string|(string|string[])[]} permissions
   * @returns {boolean}
   */
  verifyPermissions (permissions = true) {
    if (typeof permissions === 'boolean') return permissions;
    let pPermissions = (typeof permissions === 'string') ? [permissions] : permissions;
    let user = this.getUser();

    if (!user || !user.permissoes) return false;
    if (pPermissions.length === 0) return true;

    const permMatcher = permMatch(user.permissoes);

    return arrayfy(permissions).some(permMatcher);
  }

  getBearerToken () {
    return this._storage.getItem(this._bearer);
  }

  /** internal usage */
  _redefineAxiosIntercepts (token) {
    if (this._axiosIntercepts.req !== null) {
      axios.interceptors.request.eject(this._axiosIntercepts.req);
    }
    if (this._axiosIntercepts.res !== null) {
      axios.interceptors.response.eject(this._axiosIntercepts.res);
    }
    if (token) {
      this._axiosIntercepts.req = axios.interceptors.request.use(function (config) {
        config.headers['Authorization'] = 'Bearer ' + token;
        return config;
      });
      this._axiosIntercepts.res = axios.interceptors.response.use(r => r, error => {
        if (this.isLoggedIn() && error.response?.status === 401) {
          if (!this._vue) {
            alert('Sessão expirada, redirecionando para o login');
            document.location.href = '/logout';
          } else {
            this._vue.$swal.fire(
              'Sessão expirada',
              'Sua sessão expirou, por favor faça login novamente.',
              'info'
            );
            this._vue.$router.replace({
              name: 'logout',
              params: {
                next: this._vue.$route,
              },
            });
          }
        }

        if (this.isLoggedIn() && error.response?.status === 403 && error.response?.data?.error === 'Forbidden') {
          if (!this._vue) {
            if(confirm('Permissão insuficiente. Trocar usuário?')) {
              document.location.href = '/logout';
            }
          } else {
            this._vue.$swal.fire({
              title: 'Permissão insuficiente',
              text: 'Você não possui permissão suficiente para acessar este recurso (403).',
              type: 'info',
              confirmButtonText: 'Trocar de usuário',
              cancelButtonText: 'Ok',
              focusCancel: true,
              showCancelButton: true,
              showCloseButton: true,
            }).then(res => {
              if (res.value) {
                this._vue.$router.push({
                  name: 'logout',
                  params: {
                    next: this._vue.$route,
                  },
                });
              }
            });
          }
        }

        return Promise.reject(error);
      });
    } else {
      this._axiosIntercepts.req = null;
      this._axiosIntercepts.res = null;
    }
  }

}

export default new LoginManager();
